<template>
  <div class="education-data-management">
    <h3>Eğitim İçeriği Yönetimi</h3>

    <h4>{{ isEdit ? "Eğitim Verisi Düzenle" : "Yeni Eğitim Verisi Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateEducationData() : createEducationData()">
      <select v-model="selectedEducationData.education_id" required>
        <option disabled value="">Eğitimi Seçin</option>
        <option v-for="education in educations" :key="education.education_id" :value="education.education_id">
          {{ education.name }}
        </option>
      </select>
      <input v-model="selectedEducationData.title" placeholder="Başlık" required />
      <input v-model="selectedEducationData.subtitle" placeholder="Alt Başlık" />
      <input v-model="selectedEducationData.image_link" placeholder="Görsel Linki" />
      <input v-model="selectedEducationData.serial_no" placeholder="Seri Numarası" required />
      <textarea v-model="selectedEducationData.text_data" placeholder="Metin Verisi"></textarea>
      <button type="submit">{{ isEdit ? "Güncelle" : "Eğitim Verisi Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Eğitim Verileri</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="education-data-table">
      <thead>
        <tr>
          <th>Seri No</th>
          <th>Başlık</th>
          <th>Alt Başlık</th>
          <th>Eğitim</th>
          <th>Görsel Linki</th>
          <th>Metin Verisi</th>
          <th>Düzenle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in educationData" :key="data.data_id">
          <td>{{ data.serial_no }}</td>
          <td>{{ data.title }}</td>
          <td>{{ data.subtitle }}</td>
          <td>{{ getEducationName(data.education_id) }}</td>
          <td>{{ data.image_link }}</td>
          <td>{{ data.text_data }}</td>
          <td><button @click="editEducationData(data)">Düzenle</button></td>
          <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(data.data_id)">Sil</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      educationData: [],
      educations: [],
      selectedEducationData: {
        data_id: null,
        education_id: '',
        title: '',
        subtitle: '',
        image_link: '',
        serial_no: '',
        text_data: '' 
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    getEducationName(id) {
      const education = this.educations.find(ed => ed.education_id === id);
      return education ? education.name : 'Bilinmiyor';
    },

    async fetchEducationData() {
      try {
        const response = await axios.get('/admin/educationdata');
        this.educationData = response.data.educationData;
      } catch (error) {
        console.error('Eğitim verileri alınırken hata oluştu:', error);
      }
    },

    async fetchEducations() {
      try {
        const response = await axios.get('/admin/educations');
        this.educations = response.data.educations;
      } catch (error) {
        console.error('Eğitimler alınırken hata oluştu:', error);
      }
    },

    async createEducationData() {
      try {
        await axios.post('/admin/educationdata', {
          education_id: this.selectedEducationData.education_id,
          title: this.selectedEducationData.title,
          subtitle: this.selectedEducationData.subtitle,
          image_link: this.selectedEducationData.image_link,
          serial_no: this.selectedEducationData.serial_no,
          text_data: this.selectedEducationData.text_data
        });
        this.fetchEducationData();
        this.resetForm();
      } catch (error) {
        console.error('Eğitim verisi eklenirken hata oluştu:', error);
      }
    },

    editEducationData(data) {
      this.selectedEducationData = { ...data };
      this.isEdit = true;
    },

    async updateEducationData() {
      try {
        await axios.put(`/admin/educationdata/${this.selectedEducationData.data_id}`, {
          education_id: this.selectedEducationData.education_id,
          title: this.selectedEducationData.title,
          subtitle: this.selectedEducationData.subtitle,
          image_link: this.selectedEducationData.image_link,
          serial_no: this.selectedEducationData.serial_no,
          text_data: this.selectedEducationData.text_data
        });
        this.fetchEducationData();
        this.resetForm();
      } catch (error) {
        console.error('Eğitim verisi güncellenirken hata oluştu:', error);
      }
    },

    async deleteEducationData(id) {
      try {
        await axios.delete(`/admin/educationdata/${id}`);
        this.fetchEducationData();
      } catch (error) {
        console.error('Eğitim verisi silinirken hata oluştu:', error);
      }
    },

    confirmDelete(id) {
      if (confirm('Bu eğitim verisini silmek istediğinizden emin misiniz?')) {
        this.deleteEducationData(id);
      }
    },

    resetForm() {
      this.selectedEducationData = {
        data_id: null,
        education_id: '',
        title: '',
        subtitle: '',
        image_link: '',
        serial_no: '',
        text_data: ''  
      };
      this.isEdit = false;
    },

    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchEducations();
    this.fetchEducationData();
  },
};
</script>
