<template>
  <div class="subcategory-management">
    <h3>Alt Kategoriler</h3>

    <h4>{{ isEdit ? "Alt Kategori Düzenle" : "Yeni Alt Kategori Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateSubcategory() : createSubcategory()">
      <input v-model="selectedSubcategory.name" placeholder="Alt Kategori Adı" required />
      <input v-model="selectedSubcategory.description" placeholder="Açıklama" />
      <select v-model="selectedSubcategory.main_category_id" required>
        <option disabled value="">Ana Kategoriyi Seçin</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>
      <button type="submit">{{ isEdit ? "Güncelle" : "Alt Kategori Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Alt Kategoriler</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="subcategory-table">
      <thead>
        <tr>
          <th>Alt Kategori Adı</th>
          <th>Açıklama</th>
          <th>Ana Kategori</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subcategory in subcategories" :key="subcategory.id">
          <td>{{ subcategory.name }}</td>
          <td>{{ subcategory.description }}</td>
          <td>{{ getCategoryName(subcategory.main_category_id) }}</td>
          <td>
            <button @click="editSubcategory(subcategory)">Özenle</button>
            <button v-if="showDeleteButtons"  class="deletebutton"  @click="confirmDelete(subcategory.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default { 
  data() {
    return {
      subcategories: [],
      categories: [],
      selectedSubcategory: {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: { 
    getCategoryName(id) {
      const category = this.categories.find(cat => cat.id === id);
      return category ? category.name : 'Bilinmiyor';
    },

    async fetchSubcategories() {
      try {
        const response = await axios.get('/promo/sub-categories');
        this.subcategories = response.data.subCategories;
      } catch (error) {
        console.error('Alt kategoriler alınırken hata oluştu:', error); 
      }
    },

    async fetchCategories() {
      try {
        const response = await axios.get('/promo/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error); 
      }
    },

    async createSubcategory() {
      try {
        await axios.post('/promo/sub-category', {
          name: this.selectedSubcategory.name,
          description: this.selectedSubcategory.description,
          main_category_id: this.selectedSubcategory.main_category_id,
        });
        this.fetchSubcategories();
        this.resetForm(); 
      } catch (error) {
        console.error('Alt kategori eklenirken hata oluştu:', error); 
      }
    },

    editSubcategory(subcategory) {
      this.selectedSubcategory = { ...subcategory };
      this.isEdit = true;
    },

    async updateSubcategory() {
      try {
        await axios.put(`/promo/sub-category/${this.selectedSubcategory.id}`, {
          name: this.selectedSubcategory.name,
          description: this.selectedSubcategory.description,
          main_category_id: this.selectedSubcategory.main_category_id,
        });
        this.fetchSubcategories();
        this.resetForm(); 
      } catch (error) {
        console.error('Alt kategori güncellenirken hata oluştu:', error); 
      }
    },

    async deleteSubcategory(id) {
      try {
        await axios.delete(`/promo/sub-category/${id}`);
        this.fetchSubcategories(); 
      } catch (error) {
        console.error('Alt kategori silinirken hata oluştu:', error); 
      }
    },

    confirmDelete(id) {
      if (confirm('Bu alt kategoriyi silmek istediğinizden emin misiniz?')) {
        this.deleteSubcategory(id);
      }
    },

    resetForm() {
      this.selectedSubcategory = {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
      };
      this.isEdit = false;
    },

    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() { 
    this.fetchCategories();
    this.fetchSubcategories();
  },
};
</script>