<template>
  <div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div>
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import CategoryManager from './CategoryManager.vue';
import ProductGroup from './SubcategoryManager.vue';
import ProductManager from './ProductManager.vue';
import StaticPages from './StaticPages.vue';
import AgreementManagement from './AgreementManagement.vue';
import MessageMain from './MessageMain.vue';
import EducationMain from './EducationMain.vue';

export default {
  data() {
    return {
      currentComponent: 'EducationMain', 
      components: [ 
        { name: 'Kategori yönetimi', component: 'CategoryManager' },
        { name: 'Alt kategoriler', component: 'ProductGroup' },
        { name: 'Ürün yönetimi', component: 'ProductManager' },
        { name: 'Mesaj Sistemi', component: 'MessageMain' },
    { name: 'Eğitim Yönetimi', component: 'EducationMain' },
        { name: 'Master ve Öğrenci', component: 'AgreementManagement' },
        { name: 'Sabit sayfalar', component: 'StaticPages' },
        { name: 'Unvanlar', component: 'AgreementManagement' },
        { name: 'Sözleşmeler', component: 'AgreementManagement' },
      ],
    };
  },
  components: {
    CategoryManager,
    ProductGroup,
    ProductManager,
    StaticPages,
    AgreementManagement,
    MessageMain,
    EducationMain
  },
};
</script>
  
<style scoped>
.button-container {
margin: 1em 0rem; 
text-align: center;
display: flex; 
flex-wrap: wrap; 
} 

.button-container button { 
border-radius: 0.4rem;
display: inline-block;
padding: 0.2rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: 0.06rem solid #262626;
background-color: #0b0e0f;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
} 
.button-container button.active {
background-color: #1f495e;
color: #b5b5b5; 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  